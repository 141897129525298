import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { Context } from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';
import lupa from '../../midia/lupa.svg';
import { format, startOfMonth, endOfMonth } from 'date-fns';

import { PesquisaSub } from '../Pesquisas/PesquisaSub';
import { ApiEvento } from '../../services/FiltrosApi/eventoApi';
import { Functions } from '../../componentes/Functions';

// /-------------------------------------------------
export function PesquisaPadrao(props) {
    const { state, dispatch } = useContext(Context);
    const { displayModal, listaPesquisa, playmodal, parametroInit } = state.parametros;
    const { disabledEven } = parametroInit;
    const [Tudo, setTudo] = useState(false);

    const { PesqFornecedor } = ApiEvento();
    const { EditarSelect } = Functions();

    const [maisPesquisa, setmaisPesquisa] = useState(false);

    // --------------
    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, 'yyyy-MM-dd');

    const DiaPrimeiro = format(startOfMonth(dataAtual), 'yyyy-MM-dd');
    const DiaUltimo = format(endOfMonth(dataAtual), 'yyyy-MM-dd');

    const [dateInicio, setdateInicio] = useState("");
    const [dateFim, setdateFim] = useState("");

    useEffect(() => {
        if (Tudo && !listaPesquisa.nomeItem) {
            setdateInicio(Tudo && !dateInicio ? DiaPrimeiro : dateInicio);
            setdateFim(Tudo && !dateFim ? DiaUltimo : dateFim);

        } else {
            setdateInicio("");
            setdateFim("");
        }

    }, [Tudo, listaPesquisa.nomeItem])

    // --------------
    function SetListaPesquisa(key, value) {
        dispatch({
            type: 'set_ListaPesquisa',
            payload: { ...listaPesquisa, [key]: value }
        });
    };

    // --------------
    useEffect(() => {
        dispatch({
            type: 'set_ListaPesquisa',
            payload: { ...listaPesquisa, alertaNovo: false }
        });
        if (!listaPesquisa.nomeItem) {
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    listaVeiculo: [],
                    listaCliente: [],
                    listaFornecedor: [],
                    nomeItem: null
                }
            });
        }
    }, [listaPesquisa.nomeItem]);

    // --------------
    function PesquisarItem() {
        // FecharModal();
        // Limpar_Modal();
        if (displayModal.eventoDisplay && !props.PesqDentroModal && dateInicio && dateFim) {
            props.PesquisarData(dateInicio + 'T00:00:00-03:00', dateFim + 'T00:00:00-03:00', Tudo)


        } else {
            props.Pesquisar(listaPesquisa.nomeItem, Tudo);
        }
    };


    //###################################################################################################
    return (
        <S.divInfor>
            <span>{props.titulo}:</span>
            <S.ddd>
                {displayModal.clienteDisplay || playmodal.playModalUsuario || playmodal.playModalEvenPlaca ?
                    <>
                        {listaPesquisa.nomeItem?.length <= 11 ?
                            <MaskedInput
                                value={listaPesquisa.nomeItem}
                                type='search'
                                placeholder='cpf/cnpj'
                                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                onChange={e => SetListaPesquisa('nomeItem', e.target.value.replace(/\D/gim, ''))}
                                guide={false}
                            />
                            :
                            <MaskedInput
                                value={listaPesquisa.nomeItem}
                                type='search'
                                placeholder='cpf/cnpj'
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                onChange={e => SetListaPesquisa('nomeItem', e.target.value.replace(/\D/gim, ''))}
                                guide={false}
                            />
                        }
                    </>
                    :
                    props.refPlaca ?
                        <MaskedInput
                            value={listaPesquisa.nomeItem}
                            className='uppercase'
                            type='search'
                            placeholder={props.placeholder}
                            mask={[/\D/, /\D/, /\D/, /\d/, /\w/, /\d/, /\d/]}
                            onChange={e => SetListaPesquisa('nomeItem', e.target.value)}
                            guide={false}
                        />
                        :
                        props.refNomeCPF &&
                        <input
                            type='search'
                            style={{ textTransform: "capitalize" }}
                            placeholder={props.placeholder}
                            value={listaPesquisa.nomeItem}
                            onChange={e => SetListaPesquisa('nomeItem', e.target.value)}
                        />
                }

                {props.Lupa &&
                    <S.Pesq>
                        <button
                            type='button'
                            onClick={PesquisarItem}>
                            <img src={lupa} />
                        </button>
                    </S.Pesq>
                }
            </S.ddd>

            {/* ------------------------------ */}
            {(props.playBotao || props.checkTudo) &&
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {props.playBotao &&
                        <S.botao>
                            <div
                                style={{ 'backgroundColor': "#3881ff", 'color': "white" }}
                                className='abrirModal'
                                onClick={() => props.playModal_Add()}
                            >{props.nomeBotao}
                            </div>
                        </S.botao>
                    }

                    {props.checkTudo &&
                        <>
                            <S.checked>
                                <span>TUDO</span>
                                <input
                                    style={{ marginTop: "2px" }}
                                    type='checkbox'
                                    checked={Tudo}
                                    onChange={() => setTudo(Tudo ? false : true)}
                                />
                            </S.checked>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                    <div className='selectData'>
                                        <span>incio</span>
                                        <input
                                            value={dateInicio}
                                            onChange={(e) => setdateInicio(e.target.value)}
                                            type='date'
                                            max={dataFormatada}
                                        />
                                    </div>
                                    <div className='selectData'>
                                        <span>fim</span>
                                        <input
                                            value={dateFim}
                                            onChange={(e) => setdateFim(e.target.value)}
                                            type='date'
                                            min={dateInicio}
                                            max={dataFormatada}
                                        />
                                    </div>
                                    <span
                                        style={{ display: "flex", color: "yellow", alignItems: "center", cursor: "pointer" }}
                                        onClick={() => setmaisPesquisa(!maisPesquisa)}
                                    >
                                        mais
                                    </span>
                                </div>
                                {maisPesquisa &&
                                    <div>
                                        {!listaPesquisa.id_nomeForn ?
                                            <PesquisaSub
                                                nome={listaPesquisa.nomeForn}
                                                nomeItem="nomeForn"
                                                lupa={props.lupa}
                                                placeholder="Fornecedor"
                                                Pesquisar={PesqFornecedor}
                                                PesqDentroModal={true}
                                                Lupa={true}
                                            />
                                            :
                                            <span
                                                style={{ display: "flex", cursor: "pointer", textTransform: "uppercase", backgroundColor: "#30353f", padding: "3px 0", width: "100%" }}
                                                onClick={() => !disabledEven ? EditarSelect("RefFornecedor") : ""}
                                            >
                                                {listaPesquisa.SelectForn}
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            }
        </S.divInfor>

    )
}